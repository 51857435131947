import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { UserAvatar } from '@components/Avatar';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "useravatar"
    }}>{`UserAvatar`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={UserAvatar} mdxType="Props" />
    <h2 {...{
      "id": "empty-profile"
    }}>{`Empty Profile`}</h2>
    <Playground __position={1} __code={'() => {\n  const user = {\n    profilePicture: null,\n    gender: null,\n  }\n  return <UserAvatar user={user} size=\"large\" />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const user = {
          profilePicture: null,
          gender: null
        };
        return <UserAvatar user={user} size="large" mdxType="UserAvatar" />;
      }}
    </Playground>
    <h2 {...{
      "id": "gender-male"
    }}>{`Gender Male`}</h2>
    <Playground __position={2} __code={'() => {\n  const user = {\n    profilePicture: null,\n    gender: {\n      name: \'Male\',\n    },\n  }\n  return <UserAvatar user={user} size=\"large\" />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const user = {
          profilePicture: null,
          gender: {
            name: 'Male'
          }
        };
        return <UserAvatar user={user} size="large" mdxType="UserAvatar" />;
      }}
    </Playground>
    <h2 {...{
      "id": "gender-female"
    }}>{`Gender Female`}</h2>
    <Playground __position={3} __code={'() => {\n  const user = {\n    profilePicture: null,\n    gender: {\n      name: \'Female\',\n    },\n  }\n  return <UserAvatar user={user} size=\"large\" />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const user = {
          profilePicture: null,
          gender: {
            name: 'Female'
          }
        };
        return <UserAvatar user={user} size="large" mdxType="UserAvatar" />;
      }}
    </Playground>
    <h2 {...{
      "id": "profile-picture"
    }}>{`Profile Picture`}</h2>
    <Playground __position={4} __code={'() => {\n  const user = {\n    profilePicture: \'https://picsum.photos/200\',\n    gender: {\n      name: \'Male\',\n    },\n  }\n  return <UserAvatar user={user} size=\"large\" />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const user = {
          profilePicture: 'https://picsum.photos/200',
          gender: {
            name: 'Male'
          }
        };
        return <UserAvatar user={user} size="large" mdxType="UserAvatar" />;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      